<template>
    <div class="body">
        <div class="height_bg">
            <h1 style="color: white; margin-top: 30px;">酒店预约管理</h1>
            <div class="box">
                <div class="author_logo">
                    <img src="../../assets/author_logo.jpg">
                </div>
                <el-form :model="loginRuleForm" :rules="loginRules" ref="loginRuleForm" label-width="60px" class="demo-ruleForm">
                    <el-form-item label="账号" prop="username" style="margin-bottom: 30px;">
                        <el-input v-model="loginRuleForm.username"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" style="margin-bottom: 40px;">
                        <el-input v-model="loginRuleForm.password"></el-input>
                    </el-form-item>
                    <!-- <el-form-item class="btns"> -->
                    <el-button type="primary" @click="login" size="small" round>登录</el-button>
                    <el-button type="success" @click="dialogVisible = true" size="small" round>修改</el-button>
                    <!-- </el-form-item> -->
                </el-form>
                
            </div>
        </div>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
            <el-form label-width="80px" :model="accountInfo" :rules="accountInfo" ref="accountInfo">
                <el-form-item label="账号">
                <el-input v-model="accountInfo.username" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="旧密码">
                <el-input v-model="accountInfo.oldPwd" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                <el-input v-model="accountInfo.newPwd" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="changedPwd">提交</el-button>
                <el-button @click="dialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
  
<script>
    // Vue 代码逻辑
export default {
    data() {
        return {
        // 表单请求数据
        dialogVisible: false,
        loginRuleForm: {
            username: '',
            password: ''
        },
        accountInfo:{
            "username": "",
            "oldPwd": "",
            "newPwd": ""
        },
        // 表单验证规则
        loginRules: {
            username: [
            {
                required: true, message: "请输入用户名", trigger: "blur"
            },
            {
                min: 3, max: 18, message: "长度在 3 到 18 个字符", trigger: "blur",
            },
            ],
            password: [
            {
                required: true, message: "请输入密码", trigger: "blur"
            },
            {
                min: 3, max: 18, message: "长度在 3 到 18 个字符", trigger: "blur",
            },
            ]
        }
        };
    },
    methods: {
        login(){
            this.$refs.loginRuleForm.validate((vaild) => {
                if (!vaild) return;
                this.$axios(
                {
                    method: "post",
                    url:  "/api/hotel/login",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: {"username": this.loginRuleForm.username, "password": this.loginRuleForm.password, "client": "web"}
                }).then(
                    (res) => {
                        var jsonData = res.data
                        if(jsonData.state == 200){
                            this.$cookies.set('token', jsonData.Token, 1)
                            this.$router.push("/home")
                        }else{
                            console.log(jsonData)
                            this.successOpen(jsonData.msg);
                        }
                    }
                )

                console.log(this.qs.stringify(this.loginRuleForm));
                

            })
        },
        successOpen(msg){
            this.$message({
                message: msg,
                type: 'success'
            })
        },
        changedPwd(){
            if (this.accountInfo.username && this.accountInfo.oldPwd && this.accountInfo.newPwd){
                this.$axios(
                {
                    method: "post",
                    url:  "/api/hotel/changePwd",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: {"username": this.accountInfo.username, "oldPwd": this.accountInfo.oldPwd, "newPwd": this.accountInfo.newPwd}
                }).then(
                    (res) => {
                        var jsonData = res.data;
                        if (jsonData.state){
                            this.dialogVisible = false;
                        }
                        this.successOpen(jsonData.msg);
                    }
                )
            }else{
                this.$message.info("请补全信息");
            }
        }
    }
}
</script>

<style>
    .body{
        height: 100%;
        background-image: url("../../assets/dayTime.png");
	    background-repeat:no-repeat;
        background-size:cover;
    }
    .height_bg{
        height: 60%;
        width: 500px;
        transform: translate(-50%, 50%);
        position: absolute;
        left: 50%;
        background: #1F4456;
        text-align: center;
    }
    .box{
        width: 450px;
        height: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
        border-radius: 20px;
        box-shadow: 0 0 5px 2px #ddd;
        background: white;
    }
    .demo-ruleForm{
        position: absolute;
        bottom: 20px;
        padding: 0 100px;
    }
    .btns{
        display: flex;
        justify-content: flex-end;
    }
    .author_logo{
        width: 150px;
        height: 150px;
        position: absolute;
        top: -75px;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, 0);
        border: 1px solid #eee;
        box-shadow: 0 0 2px 2px #ddd;
    }
    .author_logo img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
</style>